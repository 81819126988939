import React from 'react';

import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';

import { AimTypography, AimIconButton, AimRichText } from '../atoms';

import { AimGallery } from '../bundles';

import { theme } from '../../theme';
import { styled } from '../styled';

const InnerContainer = styled('div')({
  width: '1280px',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 50,
});

const DocumentMainContainer = styled('div')({
  background: theme.colors.greyScale.backgroundGrey,
  marginBottom: 20,
});

const DocumentInnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  background: theme.colors.greyScale.white,
  margin: 50,
  padding: 10,
});

export const SponsorWelcomePageTemplate = ({
  sponsorWelcomePage,
  actionButtons,
  sponsorProspectusLabel,
}) => {
  return (
    <InnerContainer>
      <Grid container>
        <Grid item xs={12}>
          <AimTypography variant="h1Regular">
            {sponsorWelcomePage?.title}
          </AimTypography>
          <AimTypography variant="h4Regular">
            {sponsorWelcomePage?.subtitle}
          </AimTypography>
        </Grid>
      </Grid>

      <div style={{ marginTop: 20 }}>
        {sponsorWelcomePage?.cover || sponsorWelcomePage?.gallery?.length ? (
          <AimGallery
            images={(sponsorWelcomePage?.cover
              ? [sponsorWelcomePage?.cover]
              : []
            ).concat(sponsorWelcomePage?.gallery || [])}
          />
        ) : null}
      </div>

      {sponsorWelcomePage?.content && (
        <AimRichText
          value={sponsorWelcomePage?.content}
          readOnly
          variant="white"
          editorStyleOverride={{
            minHeight: 'auto',
            maxHeight: 'fit-content',
            overflow: 'hidden !important',
          }}
        />
      )}

      {sponsorWelcomePage?.document && (
        <DocumentMainContainer>
          <DocumentInnerContainer>
            <AimTypography variant="h2Regular">
              {sponsorProspectusLabel}
            </AimTypography>

            <AimIconButton
              variant="lightBlueFill"
              onClick={() => window.open(sponsorWelcomePage?.document)}
            >
              <GetAppIcon />
            </AimIconButton>
          </DocumentInnerContainer>
        </DocumentMainContainer>
      )}

      {actionButtons}
    </InnerContainer>
  );
};
