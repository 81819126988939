import React, { useState, useEffect, Children } from 'react';
import {
  format,
  parse,
  startOfWeek,
  getDay,
  isBefore,
  isAfter,
  addDays,
  set,
  subDays,
  isValid,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import {
  Calendar,
  dateFnsLocalizer,
  Views,
  Navigate,
} from 'react-big-calendar';
import { constants, appState } from '@aim/common';
import { AimTypography } from './../atoms/AimTypography';
import { AimIconAndTextButton } from './../atoms/AimIconAndTextButton';
import { AimIconButton } from './../atoms/AimIconButton';
import { styled } from './../styled';
import Grid from '@material-ui/core/Grid';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LanguageIcon from '@material-ui/icons/LanguageOutlined';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import SettingsIcon from '@material-ui/icons/Settings';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import LockIcon from '@material-ui/icons/LockOutlined';
import ZoomIn from '@material-ui/icons/ZoomInOutlined';
import ZoomOut from '@material-ui/icons/ZoomOutOutlined';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
const currentTimezone = appState.eventInfo.getValue().timezone;

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';
import { theme } from '../../theme';
import { AimDatePicker } from '../atoms/AimDatePicker';
const steps = [4, 3, 2, 1];

export const calendarVariants = {
  AGENDA_SESSION: 'agenda_session',
  BREAKOUT_ROOM: 'breakout_room',
  BREAKOUT_ROOM_SLOT: 'breakout_room_slot',
  BREAKOUT_ROOM_MEETING: 'brekout_room_meeting',
  AGENDA_ABSTRACT: constants.AgendaSpeechesTypologies.ABSTRACT,
  AGENDA_MAIN: constants.AgendaSpeechesTypologies.MAIN,
  AGENDA_DISCUSSION: constants.AgendaSpeechesTypologies.DISCUSSION,
};

const manualStatuses = [
  constants.agendaSessionManualManagementStatuses.MANUAL_MANAGED,
  constants.agendaSessionManualManagementStatuses.STARTED,
];

const StyledAimIconAndTextButton = styled(({ ...other }) => (
  <AimIconAndTextButton {...other} />
))({
  '& .MuiButtonBase-root.MuiIconButton-root': {
    width: '60% !important',
  },
});

const getSessionIcon = (type, manualManagementStatus, onClick) => {
  if (
    manualManagementStatus ===
      constants.agendaSessionManualManagementStatuses.MANUAL_MANAGED ||
    manualManagementStatus ===
      constants.agendaSessionManualManagementStatuses.STARTED
  ) {
    return <SettingsIcon />;
  }
  switch (type) {
    case 'public':
      return <LanguageIcon />;
    case 'lock':
      return <LockIcon />;
    case 'pinned':
      return <BookmarkIcon onClick={onClick} />;
    case 'unpinned':
      return <BookmarkBorderIcon onClick={onClick} />;
    default:
      return null;
  }
};

const customEventTimeRangeFormat = () => '';
const customSelectRangeFormat = ({ start, end }, culture, local) =>
  local.format(start, 'HH:mm', culture) +
  ' - ' +
  local.format(end, 'HH:mm', culture);

export const AgendaEvent = ({
  props,
  i18n,
  onPreviewClick,
  showPreview,
  onSelectEvent,
  onBookmarkClick,
}) => {
  const event = props.event;

  const handleAgendaEventIconClick = (e, type) => {
    e.stopPropagation();
    switch (type) {
      case ('pinned', 'unpinned'):
        return () => onBookmarkClick(event);
      default:
        return () => {};
    }
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      onClick={(e) => {
        e.stopPropagation();
        onSelectEvent?.(event);
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {event?.sessionName ? (
            <AimTypography
              variant={'h4'}
              style={{ color: event.color }}
              margin={0}
            >
              {event.sessionName || ''}
            </AimTypography>
          ) : null}
          <AimTypography variant={'h6'} margin={0}>
            {props.title}
          </AimTypography>
          <AimTypography variant={'text'} margin={0}>
            {`${format(event.start, 'HH:mm')} - ${format(event.end, 'HH:mm')}`}
          </AimTypography>
        </div>
        {event.status && (
          <div
            style={{
              display: 'flex',
              width: 38,
              height: 38,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4,
              border: `2px solid ${
                manualStatuses.includes(event.manualManagementStatus)
                  ? theme.colors.primary.yellow
                  : event.status === 'draft'
                  ? theme.colors.secondary.red
                  : theme.colors.secondary.green
              }`,
            }}
          >
            {getSessionIcon({
              type: event.icon || event.type,
              manualManagementStatus: event.manualManagementStatus,
              onClick: (e) => handleAgendaEventIconClick(e, event.type),
            })}
          </div>
        )}
        {!event.status &&
          getSessionIcon({
            type: event.icon || event.type,
            manualManagementStatus: event.manualManagementStatus,
          })}
      </div>
      {showPreview ? (
        <AimIconAndTextButton
          text={i18n ? i18n.calendar.buttons.showPreview.label : 'Show preview'}
          variant={'lightBlueFill'}
          onClick={(e) => {
            e.stopPropagation();
            onPreviewClick && onPreviewClick(event);
          }}
        >
          <VisibilityIcon />
        </AimIconAndTextButton>
      ) : null}
    </div>
  );
};

const AgendaToolbar = (props) => {
  if (props.showToolbar) {
    return (
      <div className={'AgendaToolbar'}>
        <Grid container justify={'space-between'}>
          <Grid item xs={2}>
            <AimIconAndTextButton
              variant={'text'}
              onClick={() => {
                props.onNavigate(Navigate.PREVIOUS);
              }}
              text={
                props.showToolbarButtonsText
                  ? props.toolbalButtonPreviousText ||
                    format(addDays(props.date, -1), 'dd/MM')
                  : ''
              }
              disabled={isBefore(subDays(props.date, 1), props.event.start)}
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Grid>
          <Grid item xs={8}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AimDatePicker
                displayLabel={false}
                variant="white"
                formControlStyle={{ margin: 0, padding: 0, maxWidth: 180 }}
                style={{
                  margin: 0,
                  padding: 0,
                }}
                minDate={props.event.start}
                maxDate={props.event.end}
                value={props.date}
                onChange={(date) =>
                  console.log('date', date) ||
                  (isValid(date) && props.onNavigate(Navigate.DATE, date))
                }
              />
            </div>
            {/* <AimTypography textAlign={'center'} variant={'h2'}>
              {props.label}
            </AimTypography> */}
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }}>
            <StyledAimIconAndTextButton
              variant={'text'}
              isIconLeft
              onClick={() => {
                props.onNavigate(Navigate.NEXT);
              }}
              text={
                props.showToolbarButtonsText
                  ? props.toolbalButtonNextText ||
                    format(addDays(props.date, 1), 'dd/MM')
                  : ''
              }
              disabled={isAfter(props.date, props.event.end)}
            >
              <ArrowForward />
            </StyledAimIconAndTextButton>
          </Grid>
        </Grid>
      </div>
    );
  }
  return null;
};

const getSessionColor = (type) => {
  switch (type) {
    case constants.AgendaSessionTypes.symposium:
      return '#FE0C4E';
    // case constants.AgendaSessionTypes.abstract:
    //   return '#3A86FF';
    case constants.AgendaSessionTypes.webinar:
      return '#30CFD0';
    case constants.AgendaSessionTypes.custom:
      return '#FFD400';
    case calendarVariants.BREAKOUT_ROOM_MEETING:
      return '#30CFD0';
    case calendarVariants.BREAKOUT_ROOM:
    case calendarVariants.BREAKOUT_ROOM_SLOT:
    case calendarVariants.AGENDA_ABSTRACT:
    case calendarVariants.AGENDA_DISCUSSION:
    case calendarVariants.AGENDA_MAIN:
      return '#FFD400';
    default:
      return '#9336FD';
  }
};

const getSessionBackgroundColor = (type) => {
  switch (type) {
    case calendarVariants.AGENDA_SESSION:
      return 'rgba(248, 246, 250, .75)';
    case calendarVariants.BREAKOUT_ROOM:
      return '#FFF3B8';
    case calendarVariants.BREAKOUT_ROOM_MEETING:
    case calendarVariants.BREAKOUT_ROOM_SLOT:
    case calendarVariants.AGENDA_ABSTRACT:
    case calendarVariants.AGENDA_DISCUSSION:
    case calendarVariants.AGENDA_MAIN:
      return '#FFF';
    default:
      return 'rgba(248, 246, 250, .75)';
  }
};

const TimeSlotWrapper = (props) =>
  React.cloneElement(Children.only(props.children), {
    style: {
      ...props.children.props.style,
      background: 'white',
      border: 0,
      fontSize: '0.875rem',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontWeight: '400',
    },
  });

const hexToRgbA = (hex) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.1)'
    );
  }
  throw new Error('Bad Hex');
};

const AgendaEventWrapper = ({
  selectedEvent,
  variant,
  children,
  event,
  cardBackGround,
}) => {
  let paddingTB = '10px';
  if (calendarVariants === calendarVariants.AGENDA_SESSION)
    if (parseInt(children.props.style.height.split('%')[0]) < 5) {
      paddingTB = '0';
    }
  const background = !cardBackGround
    ? event.color
      ? hexToRgbA(event.color)
      : getSessionBackgroundColor(event.type || variant)
    : cardBackGround;
  return React.cloneElement(Children.only(children), {
    style: {
      ...children.props.style,
      top: `calc(${children.props.style.top} + 3px)`,
      height: `calc(${children.props.style.height} - 6px)`,
      left: selectedEvent?.id === event.id ? '2%' : '4%',
      width: '96%',
      background: background,
      ...(selectedEvent?.id === event.id
        ? {
            border: `1px solid ${getSessionColor(event.type || variant)}`,
          }
        : { border: '1px solid transparent' }),
      borderLeft: `5px solid ${
        event.color ? event.color : getSessionColor(event.type || variant)
      }`,
      padding: `${paddingTB} 8px`,
      color: 'black',
    },
  });
};

const calcZonedDate = (date, tz, fn, options = null) => {
  const inputZoned = utcToZonedTime(date, tz);
  const fnZoned = options ? fn(inputZoned, options) : fn(inputZoned);
  return zonedTimeToUtc(fnZoned, tz);
};

const getZonedStartOfDay = (date, timeZone) => {
  return calcZonedDate(date, timeZone, startOfDay);
};

const getZonedEndOfDay = (date, timeZone) => {
  return calcZonedDate(date, timeZone, endOfDay);
};

export const AimCalendar = ({
  variant = calendarVariants.AGENDA_SESSION,
  selectable = true,
  event,
  sessions,
  rooms = [],
  resourceIdAccessor = 'id',
  resourceTitleAccessor = 'name',
  onSelectEvent,
  selectedEvent,
  onSelectSlot,
  style = { background: 'white' },
  defaultZoomValue = 0,
  timeslots,
  step = 15,
  i18n = null,
  showPreview = false,
  cardBackGround = '',
  onPreviewClick,
  scrollToFirst,
  showToolbar = true,
  showToolbarButtonsText = true,
  toolbalButtonNextText,
  toolbalButtonPreviousText,
  currentDay,
  minSize = false,
  maxSize = false,
  eventComponent = AgendaEvent,
  getCurrentDate,
  onBookmarkClick,
  ...rest
}) => {
  const now = utcToZonedTime(new Date(), currentTimezone);
  const [currentDate, setCurrentDate] = useState();
  const [zoomLevel, setZoomLevel] = useState(defaultZoomValue);
  const [initTime, setInitTime] = useState(now);

  useEffect(() => {
    //handle start time
    if (sessions) {
      console.log('sessions', sessions);
      let nextCurrentDate = currentDate;
      if (!currentDate) {
        if (
          isBefore(now, getZonedEndOfDay(event.end, currentTimezone)) &&
          isAfter(now, getZonedStartOfDay(event.start, currentTimezone))
        ) {
          nextCurrentDate = now;
          setCurrentDate(now);
          getCurrentDate?.(now);
        } else {
          nextCurrentDate = event.start;
          setCurrentDate(event.start);
          getCurrentDate?.(event.start);
        }
      }
      const firstSession = sessions
        .filter((s) => {
          const zonedStartOfDay = getZonedStartOfDay(
            nextCurrentDate,
            currentTimezone
          );
          const zonedEndOfDay = getZonedEndOfDay(
            nextCurrentDate,
            currentTimezone
          );

          return (
            s.start.getTime() >= zonedStartOfDay.getTime() &&
            s.end.getTime() <= zonedEndOfDay.getTime()
          );
        })
        .sort((a, b) => {
          return a.start.getHours() - b.start.getHours();
        })[0];
      if (firstSession) {
        const firstHour = firstSession.start.getHours();
        const _initTime = set(event.start, {
          hours: firstHour > 0 ? firstHour - 1 : 0,
          minutes: firstHour > 0 ? 30 : 0,
        });
        setInitTime(_initTime);
      }
    }
  }, [sessions]);

  const handleNavigate = (nextDate) => {
    setCurrentDate(nextDate);
    getCurrentDate?.(nextDate);
  };

  const toDefaultTimezoneSelectSlot = ({ start, end, ...rest }) => {
    onSelectSlot({
      ...rest,
      start: start,
      end: end,
    });
  };

  const handleZoom = (zoomIn) => {
    let zoomLvl = zoomLevel;
    if (zoomIn) {
      zoomLvl = zoomLevel + 1 < steps.length ? zoomLevel + 1 : zoomLevel;
    } else {
      zoomLvl = zoomLevel - 1 >= 0 ? zoomLevel - 1 : zoomLevel;
    }
    setZoomLevel(zoomLvl);
  };

  const ResourceHeader = (props) => {
    if (showToolbar)
      return (
        <>
          {props.index === 0 ? (
            <div className={'zoomButtons'}>
              <AimIconButton
                variant="text"
                small={'small'}
                style={{ transform: 'translate(20px, -6px)', color: '#3d527b' }}
                onClick={() => {
                  handleZoom(true);
                }}
              >
                <ZoomIn />
              </AimIconButton>
              <AimIconButton
                variant="text"
                small={'small'}
                style={{ transform: 'rotate(180deg)', color: '#3d527b' }}
                onClick={() => {
                  handleZoom(false);
                }}
              >
                <ZoomOut />
              </AimIconButton>
            </div>
          ) : null}
          <AimTypography
            variant={'text'}
            style={{ margin: '14px 0 8px' }}
            textAlign={'center'}
          >
            {props.label}
          </AimTypography>
        </>
      );
    return (
      <AimTypography
        variant={'h3'}
        style={{ margin: '14px 0 8px' }}
        textAlign={'center'}
      >
        {props.label}
      </AimTypography>
    );
  };

  const eventRender = (props) => {
    return eventComponent({
      props,
      i18n,
      onPreviewClick,
      showPreview,
      onSelectEvent,
      onBookmarkClick,
    });
  };

  const locales = {
    'en-US': enUS,
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  return (
    <Calendar
      getNow={() => utcToZonedTime(new Date(), currentTimezone)}
      selectable={selectable}
      events={sessions}
      localizer={localizer}
      defaultView={Views.DAY}
      views={[Views.DAY]}
      resources={rooms}
      resourceIdAccessor={resourceIdAccessor}
      resourceTitleAccessor={resourceTitleAccessor}
      onSelectEvent={(session) => onSelectEvent?.(session)}
      onSelectSlot={toDefaultTimezoneSelectSlot}
      onNavigate={handleNavigate}
      goToDate
      defaultDate={event.start}
      date={currentDay || currentDate}
      {...(minSize && { min: minSize })}
      {...(maxSize && { max: maxSize })}
      {...(scrollToFirst && { scrollToTime: initTime })}
      culture={'en-US'}
      formats={{
        dayHeaderFormat: 'dd/MM/yyyy',
        timeGutterFormat: 'HH:mm',
        eventTimeRangeFormat: customEventTimeRangeFormat,
        selectRangeFormat: customSelectRangeFormat,
      }}
      timeslots={timeslots || steps[zoomLevel]}
      step={step}
      components={{
        event: eventRender,
        eventWrapper: (props) =>
          AgendaEventWrapper({
            ...props,
            selectedEvent,
            variant,
            cardBackGround,
          }),
        timeSlotWrapper: TimeSlotWrapper,
        toolbar: (props) =>
          AgendaToolbar({
            ...props,
            showToolbar,
            showToolbarButtonsText,
            toolbalButtonNextText,
            toolbalButtonPreviousText,
            event,
          }),
        resourceHeader: ResourceHeader,
        agenda: { time: <div>TIME</div> },
      }}
      style={{
        ...style,
        padding: 12,
        height: `calc(${
          appState.mainContainerSize.getValue().height
        }px - 240px)`,
      }}
      {...rest}
    />
  );
};
