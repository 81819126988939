import React from 'react';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import Box from '@mui/material/Box';
import { AimTextField } from './AimTextField';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { AimTypography } from './AimTypography';

export const AimDateRangePicker = ({
  labelStart,
  labelEnd,
  displayLabel = true,
  formControlStyle,
  variant,
  labelPlacementLeft,
  customLabel,
  fieldStyle,
  addAsterisk = false,
  ...rest
}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={{ start: 'Check-in', end: 'Check-out' }}
    >
      <DateRangePicker
        inputFormat="dd/MM/yyyy"
        {...rest}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <AimTextField
              {...startProps}
              label={labelStart}
              displayLabel={displayLabel}
              textfieldVariant={variant}
              formControlStyle={formControlStyle}
              labelPlacementLeft={labelPlacementLeft}
              customLabel={customLabel}
              fieldStyle={fieldStyle}
              addAsterisk={addAsterisk}
              customInputProps={{ readOnly: true }}
            />
            {/* <Box sx={{ mx: 2 }}> to </Box> */}
            <AimTextField
              {...endProps}
              label={labelEnd}
              displayLabel={displayLabel}
              textfieldVariant={variant}
              formControlStyle={formControlStyle}
              labelPlacementLeft={labelPlacementLeft}
              customLabel={customLabel}
              fieldStyle={fieldStyle}
              addAsterisk={addAsterisk}
              customInputProps={{ readOnly: true }}
            />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

export const AimDateRangePickerForm = ({
  control,
  name,
  defaultValue = [null, null],
  errors,
  isRequired,
  labelStart,
  labelEnd,
  validate,
  onChange,
  ...rest
}) => (
  <>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <AimDateRangePicker
          labelStart={labelStart}
          labelEnd={labelEnd}
          value={props.value}
          onChange={(e) => {
            props.onChange(e);
            onChange && onChange(e);
          }}
          addAsterisk={isRequired}
          {...rest}
        />
      )}
      rules={{
        required: isRequired,
        validate: validate,
      }}
    />
    {errors && (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => {
          return (
            <div>
              <AimTypography variant="formError">
                {message ||
                  (errors?.[name]?.type === 'validate'
                    ? `is not valid`
                    : `is required`)}
              </AimTypography>
            </div>
          );
        }}
      />
    )}
  </>
);
